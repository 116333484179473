import React from "react";

export function Warning(props) {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"

      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2937 7.23752L3.41245 33.75C3.08501 34.3171 2.91176 34.96 2.90993 35.6147C2.90809 36.2695 3.07774 36.9134 3.402 37.4823C3.72625 38.0511 4.19381 38.5252 4.75815 38.8572C5.32249 39.1893 5.96395 39.3678 6.6187 39.375H38.3812C39.036 39.3678 39.6774 39.1893 40.2417 38.8572C40.8061 38.5252 41.2736 38.0511 41.5979 37.4823C41.9222 36.9134 42.0918 36.2695 42.09 35.6147C42.0881 34.96 41.9149 34.3171 41.5875 33.75L25.7062 7.23752C25.3719 6.68647 24.9013 6.23087 24.3397 5.91467C23.7781 5.59848 23.1445 5.43237 22.4999 5.43237C21.8554 5.43237 21.2218 5.59848 20.6602 5.91467C20.0986 6.23087 19.628 6.68647 19.2937 7.23752V7.23752Z"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.5 16.875V24.375"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.5 31.875H22.5188"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
